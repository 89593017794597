import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Grid,
  CardHeader,
  ToggleButtonGroup,
  ToggleButton,
  CardContent,
  CardActions,
  IconButton,
  Stack,
  Box,
  Tooltip,
} from '@mui/material'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { useNavigate, useOutletContext, useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import {
  ASSIGN_SLOT_TO_USER,
  CREATE_SEAT,
  LICENSES_QUERY,
  UNASSIGN_SLOT_FROM_USER,
  UPDATE_SEAT,
  UPDATE_USER_PRODUCT_PREFERENCES,
} from '../queries'
import get from 'lodash/get'
import { USER_QUERY_WITH_SLOTS } from '../constants/constants'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { isEmpty } from 'utils/isEmpty'
import { useSelector } from 'react-redux'
import { InPersonTable } from './(manage-delivery)/in-person-table'
import { isRrpCertified, isIlsCertified, isSspCertified } from 'utils/permissions/permissionsLogic'
import InfoIcon from '@mui/icons-material/Info'
import { RemoteClientTable, PlaylistItemProps } from './(manage-delivery)/remote-table'

interface OutletContextType {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  updateSlots: () => void
}

export const ManageDelivery = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading, loading, updateSlots } = useOutletContext<OutletContextType>()
  const { availableSspSlots, availableFocusSlots, availableRrpSlots } = useSelector((state) =>
    get(state, 'clients', {})
  )
  const authUser = useSelector((state) => get(state, 'auth.user', {}))
  const rrpCertified = isRrpCertified({ authUser })
  const sspCertified = isSspCertified({ authUser })
  const ilsCertified = isIlsCertified({ authUser })

  const [hasRemote, setHasRemote] = useState<null | boolean>(null)

  // get client data
  const { clientId: _clientId } = useParams()
  const clientId = parseInt(_clientId ?? '0', 10)

  // get all products for default value..
  const methods = useForm({
    defaultValues: {
      openConfirmation: false,
      pendingDeliveryPreference: '',
      deliveryPreference: '',
      hasSspSlots: false,
      hasRrpSlots: false,
      hasFocusSlots: false,
      email: '',
      programs: {},
      productPreferences: {},
      isInvitationMode: false,
      sspSlotStatus: '',
      rrpSlotStatus: '',
      focusSlotStatus: '',
      fullName: '',
      clientId,
    },
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isDirty, defaultValues },
  } = methods

  // watch variables
  const defaultPrograms = get(watch(), 'programs', {})
  const fullName = watch('fullName')
  const deliveryPreference = watch('deliveryPreference')
  const programs = watch('programs')
  const isRemoteClient = deliveryPreference === 'remote'
  const isInPersonClient = deliveryPreference === 'in-person'

  // query and set as default values
  const { data: licensesData, loading: loadingLicenses } = useQuery(LICENSES_QUERY, {
    variables: {
      filter: {
        isActive: true,
      },
      sort: [['product', 'order', 'ASC']],
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const initialRemoteTableValues = get(data, 'getLicenses', []).reduce(
        (accumulator, { product, id }) => {
          const programName = get(product, 'metadata.programName', '')
          const playlistItemName = product.metadata.playlistItemName
          const colour = get(product, 'metadata.clientManagementColour', '')
          const category = get(product, 'category', '')

          // base case: check for certifications
          const sspCertifiedFailed = !sspCertified && category === 'ssp'
          const rrpCertifiedFailed = !rrpCertified && category === 'rrp'
          const ilsCertifiedFailed = !ilsCertified && category === 'focus'

          const hasPassedCertification = !(
            sspCertifiedFailed ||
            rrpCertifiedFailed ||
            ilsCertifiedFailed
          )

          // add program to list and return
          if (programName && hasPassedCertification) {
            accumulator[programName] = {
              ...accumulator[programName],
              [product.id]: {
                label: playlistItemName,
                value: false,
                category,
                licenseId: id,
                colour,
              },
            }
          }
          return accumulator
        },
        {}
      )

      reset({
        ...watch(),
        programs: initialRemoteTableValues,
      })
    },
  })

  // get users licenses and populate accordingly..
  const { data: userData, loading: loadingUser } = useQuery(USER_QUERY_WITH_SLOTS, {
    variables: {
      filter: {
        ids: [clientId],
      },
    },
    skip: isEmpty(defaultPrograms),
    fetchPolicy: 'network-only',
    onCompleted: (userData) => {
      // check if we have slots
      const slots = get(userData, 'getUsers[0].slots', [])
      // possible slot statuses are: expired, used, assigned, available
      const sspSlot = slots.find(
        ({ status, category }) => ['assigned', 'used'].includes(status) && category === 'ssp'
      )
      const rrpSlot = slots.find(
        ({ status, category }) => ['assigned', 'used'].includes(status) && category === 'rrp'
      )
      const focusSlot = slots.find(
        ({ status, category }) => ['assigned', 'used'].includes(status) && category === 'focus'
      )

      const hasSspSlots = !!sspSlot
      const hasRrpSlots = !!rrpSlot
      const hasFocusSlots = !!focusSlot

      const sspSlotStatus = hasSspSlots ? sspSlot.status : ''
      const rrpSlotStatus = hasRrpSlots ? rrpSlot.status : ''
      const focusSlotStatus = hasFocusSlots ? focusSlot.status : ''

      // Extract user-specific data and overwrite default values
      const initialUserValues = get(userData, 'getUsers[0].seats', []).reduce(
        (accumulator, { productId, id, status }) => {
          // Find the program that matches the productId and update the value
          Object.keys(defaultPrograms).forEach((programName) => {
            if (defaultPrograms[programName][productId]) {
              accumulator[programName] = {
                ...accumulator[programName],
                [productId]: {
                  ...defaultPrograms[programName][productId],
                  value: status === 'active',
                  licenseId: id,
                  status,
                }, // Overwrite value to true
              }
            }
          })
          return accumulator
        },
        { ...defaultPrograms } // Start with the default values
      )

      // check if we have previously saved delivery preference, and infer otherwise
      const email = get(userData, 'getUsers[0].email', '')
      const fullName = get(userData, 'getUsers[0].fullName', '')
      const isInvitationMode = get(userData, 'getUsers[0].isInvitationMode', false)
      const productPreferences = get(userData, 'getUsers[0].productPreferences', {})
      const deliveryPreferencesByUser = get(
        userData,
        'getUsers[0].productPreferences.deliveryPreference',
        ''
      )

      // we default you to remote user if you have an email, or if you're invited
      const deliveryPreferencesByEmail = email || isInvitationMode ? 'remote' : 'in-person'
      const deliveryPreference = deliveryPreferencesByUser || deliveryPreferencesByEmail
      setHasRemote(Boolean(email || isInvitationMode))

      reset({
        deliveryPreference,
        programs: initialUserValues,
        hasSspSlots,
        hasRrpSlots,
        hasFocusSlots,
        email,
        productPreferences,
        isInvitationMode,
        sspSlotStatus,
        rrpSlotStatus,
        focusSlotStatus,
        fullName,

        // for confirmation window
        openConfirmation: false,
        pendingDeliveryPreference: deliveryPreference,
        clientId,
      })
    },
  })

  // loading hook
  useEffect(() => {
    if (licensesData && userData) {
      loading && setLoading(false)
    } else if (!loadingLicenses && !loadingUser) {
      loading && setLoading(false)
    } else {
      !loading && setLoading(true)
    }
  }, [licensesData, userData, loadingLicenses, loadingUser, loading])

  // mutations
  const [assignSlotToUser] = useMutation(ASSIGN_SLOT_TO_USER, {
    onError: () => {
      const message = 'Failed to assign program. Please refresh page and try again.'
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    },
  })
  const [unassignSlotFromUser] = useMutation(UNASSIGN_SLOT_FROM_USER)
  const [updateUserProductPreferences] = useMutation(UPDATE_USER_PRODUCT_PREFERENCES)
  const [createSeat] = useMutation(CREATE_SEAT, {
    onError: () => {
      const message = 'Failed to assign program. Please refresh page and try again.'
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    },
  })
  const [updateSeat] = useMutation(UPDATE_SEAT, {
    onError: () => {
      const message = 'Failed to assign program. Please refresh page and try again.'
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    },
  })

  const availableSlots = {
    ssp: availableSspSlots,
    focus: availableFocusSlots,
    rrp: availableRrpSlots,
  }

  const onSubmit = async (data) => {
    setLoading(true)

    const updatedData = { ...data }

    const assignSlot = async (category: string) => {
      const slotsStatusKey = `${category}SlotStatus`
      const hasUpdateableStatus = !['assigned', 'used'].includes(defaultValues?.[slotsStatusKey])
      if (availableSlots[category] > 0 && hasUpdateableStatus) {
        await assignSlotToUser({ variables: { userId: clientId, productCategory: category } })
        updatedData[`has${capitalize(category)}Slots`] = true
        updatedData[slotsStatusKey] = 'assigned'
      }
    }

    const unassignSlot = async (category: string, slotStatus: string) => {
      if (defaultValues?.[slotStatus] === 'assigned') {
        await unassignSlotFromUser({ variables: { userId: clientId, productCategory: category } })
        updatedData[slotStatus] = ''
        updatedData[`has${capitalize(category)}Slots`] = false
      }
    }

    const suspendSeat = async (licenseId: number) => {
      await updateSeat({ variables: { seat: { id: licenseId, status: 'suspended' } } })
    }

    const checkAndUnassignSlot = async (category: string) => {
      const slotStatusKey = `${category}SlotStatus`

      // Use reduce to check if any active seats exist for the given category
      const hasActiveSeats = Object.values(updatedData.programs).reduce((found, program) => {
        return (
          found ||
          Object.values(program as PlaylistItemProps).some(
            (p) => p.category === category && p.status === 'active'
          )
        )
      }, false)

      if (!hasActiveSeats) {
        await unassignSlot(category, slotStatusKey)
      }
    }

    try {
      /*
       * In person
       * - assign slots to user if any toggles are selected
       * - unassign otherwise
       */
      if (data.deliveryPreference === 'in-person') {
        for (const category of ['focus', 'ssp', 'rrp']) {
          const hasSlotsKey = `has${capitalize(category)}Slots`
          const slotsStatusKey = `${category}SlotStatus`

          if (data[hasSlotsKey]) {
            assignSlot(category)
          } else {
            unassignSlot(category, slotsStatusKey)
          }
        }
      }

      /**
       * When selecting remote:
       * - if there are any programs selected, assign the slots and seats
       * -
       */

      if (data.deliveryPreference === 'remote') {
        for (const [programName, program] of Object.entries(data.programs)) {
          for (const [productId, programValues] of Object.entries(program as PlaylistItemProps)) {
            const { value, category, licenseId, status } = programValues
            if (value) {
              if (!updatedData[`has${capitalize(category)}Slots`]) {
                await assignSlot(category)
              }

              if (!updatedData[`has${capitalize(category)}Slots`] && status === 'active') {
                await suspendSeat(licenseId)
                updatedData.programs[programName][productId].status = 'suspended'
                updatedData.programs[programName][productId].value = false
              }

              if (updatedData[`has${capitalize(category)}Slots`]) {
                if (status === 'suspended' && licenseId) {
                  await updateSeat({ variables: { seat: { id: licenseId, status: 'active' } } })
                } else if (status !== 'active') {
                  // create the seat and update updatedData
                  const response = await createSeat({
                    variables: { seat: { licenseId, userId: clientId } },
                  })
                  const createdSeatId = response.data?.createSeat?.id
                  updatedData.programs[programName][productId].licenseId = createdSeatId
                }
                updatedData.programs[programName][productId].status = 'active'
              }
            } else if (status === 'active') {
              await suspendSeat(licenseId)
              updatedData.programs[programName][productId].status = 'suspended'
              updatedData.programs[programName][productId].value = false
            }
          }
        }

        // After updating seats, check if we should unassign slots
        for (const category of ['focus', 'ssp', 'rrp']) {
          await checkAndUnassignSlot(category)
        }
      }

      // updated defaults
      reset(updatedData)
      const productPreferences = {
        ...JSON.parse(JSON.stringify(updatedData.productPreferences)),
        deliveryPreference: updatedData.deliveryPreference,
      }
      await updateUserProductPreferences({
        variables: {
          user: {
            id: clientId,
            productPreferences,
          },
        },
      })
      enqueueSnackbar('Update Successful', {
        variant: 'success',
        action: CloseSnackbarAction,
      })
    } finally {
      updateSlots()
    }
  }
  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

  const handleReset = () => {
    reset()
  }
  const navigate = useNavigate()
  const handleEnableRemoteAccess = () => {
    navigate(`/clients/${clientId}`, {
      state: {
        isRemoteAccessEnabled: true,
        editMode: true,
      },
    })
  }

  // on toggle to in-person, we have to assign toggles by programs
  const handleDeliveryPreferences = (value: string) => {
    const determineSlotFlags = (programs: Record<string, Record<string, PlaylistItemProps>>) => {
      let hasSspSlots = false
      let hasRrpSlots = false
      let hasFocusSlots = false

      // Iterate over each program (SSP Connect, SSP Core, etc.)
      Object.values(programs).forEach((program) => {
        // Iterate over each item inside the program
        Object.values(program).forEach(({ value, category, status }) => {
          if (category === 'ssp' && status === 'active') hasSspSlots = true
          if (category === 'rrp' && status === 'active') hasRrpSlots = true
          if (category === 'focus' && status === 'active') hasFocusSlots = true
        })
      })

      return { hasSspSlots, hasRrpSlots, hasFocusSlots }
    }

    // Example Usage in Initialization
    const { hasSspSlots, hasRrpSlots, hasFocusSlots } = determineSlotFlags(programs)

    setValue('deliveryPreference', value)
    setValue('hasSspSlots', hasSspSlots || defaultValues?.hasSspSlots)
    setValue('hasRrpSlots', hasRrpSlots || defaultValues?.hasRrpSlots)
    setValue('hasFocusSlots', hasFocusSlots || defaultValues?.hasFocusSlots)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
        <Grid container px={2} py={1}>
          <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
            <Card variant="outlined">
              <CardHeader
                title={`Program Delivery Preferences for ${fullName}`}
                action={
                  <Tooltip
                    title={
                      <div role="tooltip">
                        <strong>Control Client Listening Preferences</strong>
                        <p>
                          <strong>To allow remote listening:</strong> Enable programs under{' '}
                          <em>Remote Delivery</em> to allow clients to access them remotely through
                          the Unyte Health App. In-person sessions remain available through your
                          provider account.
                        </p>
                        <p>
                          <strong>To limit listening to in-person only:</strong> Disable all
                          programs under <em>Remote Delivery</em> and ensure programs are enabled
                          under <em>In-Person Delivery</em>. This restricts client access to the
                          Unyte Health App, while assessments and resources remain accessible in
                          MyUnyte.
                        </p>
                      </div>
                    }
                  >
                    <IconButton aria-label="info" color="primary">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                }
              />
              <CardContent>
                <Stack direction="row" spacing={1}>
                  <Controller
                    name="deliveryPreference"
                    control={control}
                    render={({ field }) => (
                      <ToggleButtonGroup
                        {...field}
                        color="primary"
                        exclusive
                        aria-label="Client Delivery Preferences Toggle"
                        onChange={(_, value) => {
                          if (value !== null) {
                            handleDeliveryPreferences(value)
                          }
                        }}
                      >
                        <ToggleButton value="in-person" sx={{ textTransform: 'none' }}>
                          In-person Delivery
                        </ToggleButton>
                        <ToggleButton
                          value="remote"
                          disabled={hasRemote !== null && !hasRemote}
                          sx={{ textTransform: 'none' }}
                        >
                          Remote Delivery
                        </ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  />
                  {hasRemote !== null && !hasRemote && (
                    <Button onClick={handleEnableRemoteAccess}>Enable Remote Access</Button>
                  )}
                </Stack>
                {isRemoteClient && <RemoteClientTable handleSubmit={onSubmit} />}
                {isInPersonClient && <InPersonTable />}
              </CardContent>
              <CardActions>
                <Stack direction="row" justifyContent="space-between" width="100%" px={1} pb={4}>
                  <Box>
                    <Button type="submit" variant="contained" disabled={!isDirty}>
                      Save Changes
                    </Button>
                    <Button type="reset" disabled={!isDirty}>
                      Cancel Changes
                    </Button>
                  </Box>
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
