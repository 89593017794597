import React from 'react'
import {
  Button,
  Switch,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Controller, useFormContext } from 'react-hook-form'
import get from 'lodash/get'
import CircleIcon from '@mui/icons-material/Circle'
import { useSelector } from 'react-redux'

export interface PlaylistItemProps {
  label: string
  value: boolean
  category: string
  licenseId: number
  colour: string
  status: string
}

interface PlaylistsProps {
  [key: string]: PlaylistItemProps
}

export const RemoteClientTable = ({ handleSubmit }) => {
  const { getValues, setValue } = useFormContext() // Access form context

  const formValues = getValues('programs') as Record<string, PlaylistsProps>
  const fullName = getValues('fullName')

  // get custom message by number of playlists selected
  const transformProgramsToSentence = (programs: any, fullName: string) => {
    const enabledPrograms: string[] = []

    for (const programType in programs) {
      for (const programId in programs[programType]) {
        const program = programs[programType][programId]
        if (program.value) {
          enabledPrograms.push(`${programType} ${program.label}`)
        }
      }
    }

    if (enabledPrograms.length === 0) {
      return `${fullName} does not have access to any programs through their Unyte Health.`
    }

    enabledPrograms.sort((a, b) => a.localeCompare(b))
    const programList = enabledPrograms.join(', ').replace(/, ([^,]*)$/, ' and $1')
    return `${fullName} has access to the following through their Unyte Health: ${programList}.`
  }

  const sentence = transformProgramsToSentence(formValues, fullName)

  /**
   * Diable all active programs
   * 1. get all activated programs
   * 2. disable all programs
   */
  const handleDisableAllPrograms = async () => {
    // 1. Get the current form values
    const getDisableAllPrograms = async (data) => {
      const updatedData = { ...data }

      // 2. Update all active programs to false & set status to "suspended"
      Object.values(updatedData.programs).forEach((program) => {
        Object.values(program as PlaylistItemProps).forEach((item) => {
          if (item.value === true) {
            item.value = false
          }
        })
      })
      return updatedData
    }

    const data = getValues() // Get latest form values
    const updatedData = await getDisableAllPrograms(data)

    // 3. Set the updated values in the form before submitting
    setValue('programs', updatedData.programs, { shouldValidate: true })

    // 3. Call onSubmit with the updated data
    await handleSubmit(updatedData)
  }

  return (
    <Stack pt={4} spacing={2}>
      <Typography variant="body2">{sentence}</Typography>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ my: 2, height: 'calc(75vh - 300px)', overflowY: 'auto', paddignBottom: '80px' }}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Program</TableCell>
              <TableCell>Playlist</TableCell>
              <TableCell>Enable Remote Delivery</TableCell>
              <TableCell>
                <Button onClick={handleDisableAllPrograms}>Disable all programs</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(formValues).map((programName) => (
              <Row
                key={programName}
                programName={programName}
                playlists={formValues[programName]}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

const Row = ({ programName, playlists }: { programName: string; playlists: PlaylistsProps }) => {
  const [open, setOpen] = React.useState(false) // Track if the row is expanded
  const { control, formState } = useFormContext() // Access form context
  const { availableSspSlots, availableRrpSlots, availableFocusSlots } = useSelector((state) =>
    get(state, 'clients', {})
  )

  // get default values
  const hasSspSlots = formState.defaultValues?.hasSspSlots
  const hasRrpSlots = formState.defaultValues?.hasRrpSlots
  const hasFocusSlots = formState.defaultValues?.hasFocusSlots

  // Toggle to open/close the row
  const toggleOpen = () => setOpen(!open)
  const color = get(Object.values(playlists)[0], 'colour', '#FFF')

  const hasAvailableSspSlots =
    (availableSspSlots || hasSspSlots) &&
    Object.values(playlists).some((item: PlaylistItemProps) => item.category === 'ssp')

  const hasAvailableRrpSlots =
    (availableRrpSlots || hasRrpSlots) &&
    Object.values(playlists).some((item: PlaylistItemProps) => item.category === 'rrp')

  const hasAvailableFocusSlots =
    (availableFocusSlots || hasFocusSlots) &&
    Object.values(playlists).some((item: PlaylistItemProps) => item.category === 'focus')

  const hasAvailability = hasAvailableFocusSlots || hasAvailableSspSlots || hasAvailableRrpSlots

  // get the number of playlists selected
  const selectedPlaylist = Object.values(playlists).filter(
    (item: PlaylistItemProps) => item.value === true
  )
  const selectedPlaylistCount = selectedPlaylist.length
  const totalPlaylistCount = Object.keys(playlists).length

  const transfromSelectedPlaylistCount = selectedPlaylistCount
    ? `${selectedPlaylistCount}/${totalPlaylistCount} Enabled`
    : 'None Enabled'

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={toggleOpen}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" disabled={!hasAvailability}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Stack justifyContent="flex-start" alignItems="center" direction="row" spacing={1}>
            <CircleIcon sx={{ color }} />
            <Typography noWrap variant="body2">
              {programName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell />
        <TableCell>
          <Typography noWrap variant="body2" ml={1.5}>
            {transfromSelectedPlaylistCount}
          </Typography>
        </TableCell>
        <TableCell align="left">{!hasAvailability && 'No available licenses'}</TableCell>
      </TableRow>

      {open &&
        Object.keys(playlists)
          .sort((a, b) => playlists[a].label.localeCompare(playlists[b].label)) // Sort by label
          .map((productId) => {
            const playlistItem = playlists[productId].label

            return (
              <TableRow key={productId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell colSpan={2} />
                <TableCell>
                  <Stack
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                  >
                    <CircleIcon sx={{ color }} />
                    <Typography noWrap variant="body2">
                      {playlistItem}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="left">
                  {!hasAvailability && 'No available licenses'}
                  {!!hasAvailability && (
                    <Controller
                      name={`programs.${programName}.${productId}.value`}
                      control={control}
                      render={({ field }) => <Switch {...field} checked={field.value} />}
                    />
                  )}
                </TableCell>

                <TableCell />
              </TableRow>
            )
          })}
    </>
  )
}
