import React, { useState, useEffect } from 'react'
import { Container, Grid, Stack, Typography, Divider, Skeleton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import { CertificationCard } from './(components)/certification-card'
import { hasActiveProductAccess } from 'utils/permissions/permissionsLogic'
import { InfoMessageCard } from 'components/info-message-card'
import { useQuery, gql, useMutation } from 'utils/apollo'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { useNavigate, useOutletContext } from 'react-router'
import { AcademyContextType } from './academy-layout'
import AcademyLogoAvatar from './academy-logo-avatar'
import { Link as RouterLink } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'

enum TrainingTypes {
  talentLMS = 'talentLMS',
  redirectLink = 'redirectLink',
}

interface UserTraining {
  userId: string
  completedAt: string
  enrolledAt: string
}

interface Training {
  id: number
  isEnabled: boolean
  key: string
  longDescription: string
  metadata: any
  shortDescription: string
  title: string
  type: string
  userTrainings: UserTraining[] // Should have only when element for current user when enrolled or compelted certification
}

interface TrainingQueryResponse {
  getTrainings: {
    rows: Training[]
    count: number
  }
}

interface CreateUserTrainingResponse {
  createUserTraining: UserTraining
}

const GET_TRAININGS = gql`
  query GET_TRAININGS($filter: FilterTrainingInput, $sort: [[String]], $offset: Int, $limit: Int) {
    getTrainings(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
      rows {
        id
        isEnabled
        key
        longDescription
        metadata
        shortDescription
        title
        type
        userTrainings {
          enrolledAt
          completedAt
        }
      }
    }
  }
`

const CREATE_USER_TRAINING = gql`
  mutation CREATE_USER_TRAINING($trainingId: Int!) {
    createUserTraining(trainingId: $trainingId) {
      enrolledAt
      completedAt
    }
  }
`

export default function AdvancedTraining(): React.ReactNode {
  const { talentURL } = useOutletContext<AcademyContextType>()
  const org = useSelector((state) => get(state, 'organization'))
  const authUser = useSelector((state) => get(state, 'auth.user'))
  const hasAccessToTrainings = hasActiveProductAccess({ authUser, org })
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(true)
  const [loadingTrainingId, setLoadingTrainingId] = useState<number | null>(null)
  const [trainings, setTrainings] = useState<Training[]>([])
  const showAdvancedTraining = useSelector((state) => get(state, 'ff.showAdvancedTraining', false))
  const navigate = useNavigate()
  const [createUserTraining] = useMutation(CREATE_USER_TRAINING)

  useQuery(GET_TRAININGS, {
    fetchPolicy: 'cache-and-network',
    skip: !showAdvancedTraining && !hasAccessToTrainings,
    variables: {
      filter: {
        category: ['advanced'],
      },
    },
    onCompleted: (data: TrainingQueryResponse) => {
      setTrainings(data.getTrainings.rows)
      setLoading(false)
    },
    onError: (err: Error) => {
      setLoading(false)
      console.error(err)
      enqueueSnackbar(
        `Failed to load advanced trainings. Please try again later. Error message: ${err.message}`,
        {
          variant: 'error',
          action: CloseSnackbarAction,
        }
      )
    },
  })

  useEffect(() => {
    if (!showAdvancedTraining) {
      navigate('/')
    }
  }, [showAdvancedTraining])

  const handleCreateTraining = (training: Training) => {
    setLoadingTrainingId(training.id)

    createUserTraining({
      variables: { trainingId: training.id },
      onCompleted: (data: CreateUserTrainingResponse) => {
        const newTrainings = trainings.map((t) =>
          t.id === training.id ? { ...t, userTrainings: [data.createUserTraining] } : t
        )
        setTrainings(newTrainings)

        if (training.type === TrainingTypes.talentLMS && talentURL) {
          window.location.href = talentURL
        } else if (training.type === TrainingTypes.redirectLink && training.metadata.redirectLink) {
          window.open(training.metadata.redirectLink)
        }
        setLoadingTrainingId(null)
      },
      onError: (err: Error) => {
        setLoadingTrainingId(null)
        console.error(err)
        enqueueSnackbar(`Failed to enroll. Please try again later. Error message: ${err.message}`, {
          variant: 'error',
          action: CloseSnackbarAction,
        })
      },
    })
  }

  if (!hasAccessToTrainings) {
    return (
      <InfoMessageCard
        icon={<AcademyLogoAvatar />}
        title="Build upon your foundational knowledge with our advanced courses!"
        descriptionNode={
          <Typography>
            Access to these courses will be available after{' '}
            <MuiLink component={RouterLink} to={talentURL || '/academy'}>
              completing your training
            </MuiLink>{' '}
            and{' '}
            <MuiLink component={RouterLink} to="/store">
              purchasing a subscription
            </MuiLink>
            .
          </Typography>
        }
      />
    )
  }

  const getButtonText = (training: Training): string => {
    if (training.type === 'redirectLink') return 'View Qualifications'

    const isTrainingEnrolled = get(training, 'userTrainings[0].enrolledAt', false)
    return isTrainingEnrolled ? 'Access Here' : 'Enroll Here'
  }

  return (
    <Stack direction="column" spacing={2} px={2}>
      <Typography variant="h4" component="h5" px={4} pt={2}>
        These courses are included in your subscription. Click the button within the training to
        enroll.
      </Typography>
      <Container maxWidth="lg">
        <Grid container direction="row" spacing={2}>
          {loading &&
            [1, 2, 3].map((key) => (
              <CertificationCard key={key} title={<Skeleton />} content={<Skeleton />} />
            ))}
          {!loading &&
            trainings.map((training) => {
              const isTrainingLoading = training.id === loadingTrainingId

              return (
                <CertificationCard
                  key={training.key}
                  title={training.title}
                  cardHeaderSx={{
                    '@media (max-width: 239px)': {
                      height: 'auto',
                    },
                    '@media (min-width: 240px)': {
                      height: '6rem', // Reset to auto or specify a different height above 240px
                      mt: 2,
                    },
                    alignItems: 'flex-start',
                  }}
                  content={training.longDescription}
                >
                  <Stack
                    direction="column"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    className="w-full"
                  >
                    {training.shortDescription && (
                      <Stack
                        direction="column"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Divider className="w-full" />
                        <Typography color="primary" textAlign="center">
                          {training.shortDescription}
                        </Typography>
                      </Stack>
                    )}
                    <LoadingButton
                      variant="contained"
                      sx={{
                        marginX: 'auto',
                      }}
                      onClick={() => handleCreateTraining(training)}
                      loading={isTrainingLoading}
                    >
                      {getButtonText(training)}
                    </LoadingButton>
                  </Stack>
                </CertificationCard>
              )
            })}
        </Grid>
      </Container>
    </Stack>
  )
}
